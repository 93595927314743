import Alpine from '../alpine.js'

Alpine.data('flashMessage', (message) => ({
  show: false,
  message,
  init () {
    this.$nextTick(() => this.show = true)
    setTimeout(() => this.dismissMessage(), 2500)
  },
  dismissMessage (message) {
    this.show = false
    setTimeout(() => this.removeMessage(message), 500)
  }
}))
