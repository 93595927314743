import Alpine from "../alpine.js"

Alpine.data("turboForm", () => ({
  init () {
    // For some reason using target="_top" doesn't work inside a modal
    // and for form errors, it expects a 4xx or 5xx to rerender with form errors
    // this handles the redirecting manually without having to mess with how the
    // Controllers works for now
    this.$root.addEventListener("turbo:before-fetch-response", (event) => {
      const response = event?.detail?.fetchResponse?.response
      if (response?.ok && response?.redirected) {
        event.preventDefault()
        Turbo.visit(response.url)
      }
    })
  }
}))
