import Alpine from '../alpine.js'

Alpine.data('flash', (messages = []) => ({
  messages: messages,
  addMessage (message) {
    this.messages.push(message)
  },
  removeMessage (message) {
    this.messages.splice(this.messages.indexOf(message), 1)
  },
}))
