import * as Turbo from "@hotwired/turbo"
import Alpine from "./src/alpine.js"
window.Alpine = Alpine
window.Turbo = Turbo

import "./src/components/flash.js"
import "./src/components/profile.js"
import "./src/components/datepicker.js"
import "./src/components/turbo_form.js"
import "./src/components/flash_message.js"

document.addEventListener("DOMContentLoaded", () => {
  Alpine.start()
})
