import flatpickr from "flatpickr"
import Alpine from "../alpine.js"

Alpine.data("datepicker", (value = null) => ({
   value,
   picker: null,
    init () {
      this.$nextTick(() => {
        this.picker = flatpickr(this.$refs.input, {
          defaultDate: this.value,
        })
      })
      this.$watch('value', () => this.picker && this.picker.setDate(this.value))
    }
}))
