import Alpine from 'alpinejs'
import UI from "@alpinejs/ui"
import Focus from "@alpinejs/focus"
import Persist from '@alpinejs/persist'
import Collapse from '@alpinejs/collapse'

Alpine.plugin(UI)
Alpine.plugin(Focus)
Alpine.plugin(Persist)
Alpine.plugin(Collapse)

export default Alpine
